import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material'
import { CKEditorField } from 'components/FormFields/CKEditor/CkEditorField'
import { CurrencyField } from 'components/FormFields/CurrencyField'
import { InputField } from 'components/FormFields/InputField'
import { SelectField } from 'components/FormFields/SelectField'
import { StatusField } from 'components/FormFields/StatusField'
import { UploadField } from 'components/FormFields/UploadField'
import { languageOptions } from 'constants/language'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

const schema = yup.object().shape({
  GenresName: yup.string().required('Genre name is required'),
  //   ImageBackground: yup.string().required('Image is required'),
  //   ImageBanner: yup.string().required('Image is required'),
  ImagesPaths: yup.string().required('Image is required'),
})

export function AddEditGenreForm({
  data,
  isEdit,
  onSubmit,
  onLanguagechange,
  language,
}) {
  const { t } = useTranslation()

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      GenresName: '',
      LanguagesID: language || 'vi-VN',
      StatusID: true,
      ShortDescription: '',
      Description: '',
      Amount: '',
      ImagesPaths: '',
      ImageBanner: '',
      isHot: false,
    },

    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (data) {
      const newData = {
        ...data,
        StatusID: Boolean(data?.StatusID),
      }
      Object.keys(newData).forEach((key) => {
        setValue(key, newData[key])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleFormSubmit = handleSubmit((formValues) => {
    onSubmit?.({
      ...formValues,
      StatusID: formValues.StatusID ? 1 : 0,
    })
  })

  return (
    <Stack component="form" spacing={3} noValidate onSubmit={handleFormSubmit}>
      <Paper elevation={3}>
        <Box p={3}>
          <Typography variant="h6" fontWeight="bold">
            {t('Detail')}
          </Typography>

          <Typography variant="body2">
            {`${t('Genre Name')}, ${t('language')}, ${t('status')},...`}
          </Typography>
        </Box>

        <Divider />

        <Stack spacing={3} p={3}>
          <Stack direction="row">
            <Box>
              <StatusField
                name="StatusID"
                label={t('status')}
                control={control}
              />
            </Box>
            <Box>
              <Typography variant="caption"></Typography>
              <StatusField
                label="Sự kiện hot"
                name="IsHot"
                color="success"
                control={control}
              />
            </Box>
          </Stack>
          <Box>
            <InputField
              name="GenresName"
              label={t('Genre Name')}
              control={control}
            />
          </Box>

          <Box>
            <CurrencyField
              name="Amount"
              label={t('Unit Price')}
              control={control}
            />
          </Box>

          <Box>
            <SelectField
              name="LanguagesID"
              label={t('language')}
              control={control}
              optionList={languageOptions}
              onChange={(value) => onLanguagechange?.(value)}
            />
          </Box>

          <Box>
            <CKEditorField
              name="Description"
              label={t('description')}
              control={control}
            />
          </Box>

          <Box sx={{ width: 1 / 3 }}>
            <Typography variant="body2" gutterBottom>
              Hình thẻ
            </Typography>

            <UploadField
              name="ImagesPaths"
              control={control}
              aspectRatio="3/4"
            />
          </Box>

          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
              Hình nền (16:9)
            </Typography>

            <UploadField
              name="ImageBackground"
              control={control}
              aspectRatio="16/9"
            />
          </Box>

          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
              Hình banner
            </Typography>

            <UploadField
              name="ImageBanner"
              control={control}
              aspectRatio="3/1"
            />
          </Box>
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent="flex-end" p={3}>
          <Button variant="contained" type="submit">
            {isEdit ? t('Update') : t('Create')}
          </Button>
        </Stack>
      </Paper>
    </Stack>
  )
}
