import { Box } from '@mui/material'
import { MainLoading } from 'components/Common/MainLoading'
import { useGenres } from 'hooks/Genres/useGenres'
import { useNewsList } from 'hooks/News/useNewsList'
import { useSelector } from 'react-redux'
import { About } from '../components/About'
import { HeroBanner } from '../components/HeroBanner'
import { HotGenreList } from '../components/HotGenre'
import { News } from '../components/News'
import { Rules } from '../components/Rules'

export default function Home() {
    const language = useSelector((state) => state.global.language)
    const { data: ruleList, isLoading: ruleLoading } = useGenres({
        page: 1,
        LanguagesID: language,
    })

    const { data: newsList, isLoading: newsLoading } = useNewsList({
        page: 1,
        limit: 6,
        LanguagesID: language,
    })

    return ruleLoading || newsLoading ? (
        <MainLoading />
    ) : (
        <Box>
            <Box
                sx={{
                    background:
                        'linear-gradient(180deg, #fffbca 35%, rgba(255, 251, 202, 0) 100%);',
                }}
            >
                <HeroBanner ruleList={ruleList} />
                <About />
            </Box>

            <Rules ruleList={ruleList} />
            <HotGenreList
                ruleList={
                    (Array.isArray(ruleList) &&
                        ruleList.length > 0 &&
                        ruleList.filter((item) => item.IsHot === true)) ||
                    []
                }
            />
            <News newsList={newsList} />
        </Box>
    )
}
