import { SearchBox } from 'components/FormFields/SearchBox'
import { SortBox } from 'components/FormFields/SortBox'
import { Box, Button, Stack } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

export function PlayerFilter({
  params,
  onFilterChange,
  eventList = [],
  genreList = [],
  onExport,
  onCreate,
}) {
  function handleSearchChange(value) {
    onFilterChange({ ...params, key: value })
  }
  function handleChangeEventID(value) {
    onFilterChange({ ...params, eventID: value })
  }
  function handleChangeGenresID(value) {
    onFilterChange({ ...params, genresID: value })
  }
  function handleChangeTypeUserID(value) {
    onFilterChange({ ...params, TypeUserID: value })
  }

  return (
    <Box>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="flex-end"
        sx={{ mx: -1 }}
      >
        <Box>
          <Box sx={{ p: 1 }}>
            <SearchBox onSearchChange={handleSearchChange} />
          </Box>
        </Box>

        <Box sx={{ p: 1 }}>
          <SortBox
            label="Lọc theo vai trò"
            onChange={handleChangeTypeUserID}
            defaultValue="0"
            hideOptionAll
            optionList={[
              {
                label: 'Người chơi',
                value: '3',
              },
              {
                label: 'Khách mời',
                value: '5',
              },
              {
                label: 'Tất cả',
                value: '0',
              },
            ]}
          />
        </Box>

        <Box sx={{ p: 1 }}>
          <SortBox
            label="Lọc theo sự kiện"
            onChange={handleChangeEventID}
            defaultValue="0"
            hideOptionAll
            optionList={
              eventList.length > 0
                ? eventList.concat([
                    {
                      label: 'Tất Cả',
                      value: '0',
                    },
                  ])
                : []
            }
          />
        </Box>
        <Box sx={{ p: 1 }}>
          <SortBox
            label="Lọc theo thể loại"
            onChange={handleChangeGenresID}
            defaultValue="0"
            hideOptionAll
            optionList={
              genreList.length > 0
                ? genreList.concat([{ label: 'All', value: '0' }])
                : []
            }
          />
        </Box>
      </Stack>

      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="flex-end"
        sx={{ mx: -1 }}
      >
        <Box sx={{ p: 1 }}>
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={() => onCreate?.()}
            color="success"
          >
            Thêm mới
          </Button>
        </Box>

        <Box sx={{ p: 1 }}>
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={() => onExport?.()}
          >
            Export
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}
